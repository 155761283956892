import { StyleConstants } from './constants/styles';
import { debounce } from './generic/helpers/Debounce';

type ScreenType = 'mobile' | 'desktop';

(() => {

	const siteSearchForm: HTMLFormElement = document.getElementById('site-search-form') as HTMLFormElement;
	const siteSearchLink: HTMLAnchorElement = document.getElementById('site-search-link') as HTMLAnchorElement;

	let screenType: ScreenType | undefined;
	let tearDownFunction: () => void | undefined;

	function setupDesktopSearch(): () => void {

		siteSearchLink.classList.add('hide');
		siteSearchLink.setAttribute('aria-hidden', 'true');
		siteSearchLink.setAttribute('tabindex', '-1');

		siteSearchForm.classList.remove('hide');
		siteSearchForm.removeAttribute('aria-hidden');
		siteSearchForm.removeAttribute('tabindex');

		return function tearDown(): void {
		}
	}

	function setupMobileSearch(): () => void {

		siteSearchLink.classList.remove('hide');
		siteSearchLink.removeAttribute('aria-hidden');
		siteSearchLink.removeAttribute('tabindex');

		siteSearchForm.classList.add('hide');
		siteSearchForm.setAttribute('aria-hidden', 'true');
		siteSearchForm.setAttribute('tabindex', '-1');

		return function tearDown(): void {
		}
	}

	function setScreenType(): void {

		function detectScreenType(): ScreenType {
			const width = window.innerWidth;
			const isMobile = width && width <= StyleConstants.SearchCollapse;
			return isMobile ? 'mobile' : 'desktop';
		}

		const newScreenType = detectScreenType();

		// only act if it's changed
		if (screenType !== newScreenType) {

			if (tearDownFunction) {
				tearDownFunction();
			}

			tearDownFunction = newScreenType === 'desktop'
				? setupDesktopSearch()
				: setupMobileSearch();

			screenType = newScreenType;
		}
	}

	setScreenType();
	const debouncedSetScreenType = debounce(setScreenType, 100);
	window.addEventListener('resize', debouncedSetScreenType);
})();
